import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const seoQuery = graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `
const SEO = ({ data, description, lang, meta, title }) => {
  const metaDescription = description || data.site.siteMetadata.description
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Oswald:wght@400&display=swap" rel="stylesheet" />
      <script type="text/javascript" src="//fast.fonts.net/jsapi/4e2f5516-1cdc-4b78-a2a0-44454f03cfaf.js"></script>
    </Helmet>
  )
}

export default props => (
  <StaticQuery
    query={seoQuery}
    render={ data => <SEO data={data} {...props} /> }
  />
)

SEO.defaultProps = {
  lang: `fr-ca`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}
